import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/masterplan',
    name: 'masterplan',
    component: () => import(/* webpackChunkName: "masterplan" */ '../views/MasterPlan.vue')
  },
  {
    path: '/floorplan',
    name: 'Floorplan',
    component: () => import(/* webpackChunkName: "floorplan" */ '../views/Floorplan.vue'),
    beforeEnter:(to,from,next)=>{
      // if(!store.getters.selectedTypology || !store.getters.selectedTypology.key){
      //   next({name:'Home'});
      // }else{
        next();
      // }
    }
  },
  {
    path: '/Unit',
    name: 'Unit',
    component: () => import(/* webpackChunkName: "unitplan" */ '../views/Unitplan.vue'),
    beforeEnter:(to,from,next)=>{
      if(!store.getters.selectedFloor || !store.getters.selectedFloor.key){
        next({name:'Floorplan'});
      }else{
        next();
      }
    }
  },
  {
    path: '/Inventory',
    name: 'Inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../views/Inventory.vue')
  },
  {
    path: '/PaymentDetails',
    name: 'PaymentDetails',
    component: () => import(/* webpackChunkName: "paymentdetails" */ '../views/PaymentDetails.vue'),
    beforeEnter:(to,from,next)=>{
      if(!store.getters.selectedFloor || !store.getters.selectedFloor.key){
        next({name:'Floorplan'});
        next({name:'Home'});
      }
      else{
        if(!store.getters.amount){
          next({name:'Unit'});
        }else{
          next();
        }
      }
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
